<template>
<!--  <div class="row m-0 mt-md-5 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">关于我们</div>
      <div class="list-group p-0">
<!--        <a href="/company" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown active">中心简介</a>-->
<!--        <a href="/leader" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心领导</a>-->
<!--        <a href="#" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">职能部门</a>-->
<!--        <a href="/leadership" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">领导讲话</a>-->
        <a href="javascript:void(0);" @click="getNoticeByType(1)" class="bg-light font-weight-bold text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown active">中心简介</a>
        <a href="/train" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心领导</a>
        <a href="/funcDep" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">职能部门</a>
        <a href="/leadership" @click="getNoticeByType(5)" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">领导讲话</a>

      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp">
      <div class="text-left h3">{{ noticeData.noticeTitle }}</div>
      <div class="text-left small">
        <span>{{ noticeData.upDate }}</span><span class="pl-md-3 pr-md-3"> / </span><span>{{ noticeData.userId }}</span>
        <span class="pl-md-3 pr-md-3"> / </span><span><i class="fa fa-eye" aria-hidden="true"> 209</i></span>
      </div>
      <div class="text-left mt-md-2 mb-md-2 w-100 p-2 ql-container ql-snow">
        <span class="ql-editor" v-html="noticeData.notice" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "company",
  data() {
    return {
      noticeData: ''
    }
  },
  mounted() {
    this.getNoticeByType(1)
  },
  methods: {
    getNoticeByType(noticeType) {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getCMSNotice/' + noticeType
      }).then((res) =>{
        this.noticeData = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>